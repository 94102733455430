<template>
    <section id="workingArea">
        <div class="row">
            <div class="col-xs-12 col-md-4 col-lg-4 q-pa-md">
                <div class="justify-center items-center text-center q-mb-md">
                    <h3 class="treTitle">Treasury Status</h3>
                </div>
                <div class="treasury__status q-pa-md">
                    <div class="q-px-lg">
                        <div class="q-pt-md q-pb-lg">
                            <div class="flex items-center">
                                <div>
                                    <img :src="require(`@/assets/images/starrw.png`)" 
                                        style="width: 30px;height:30px"/>
                                </div>
                                <div class="justify-center flex items-baseline q-pl-sm" style="color:#abc4ed">
                                    <h6 class="q-my-none">
                                        ${{treasuryBalance}}
                                    </h6>
                                </div>
                            </div>
                            <p class="label q-mt-sm" style="padding-left:40px;">Treasury Balance</p>
                        </div>
                        <div class="q-py-lg">
                            <div class="flex items-center">
                                <div>
                                    <img :src="require(`@/assets/images/tokens/star.png`)" 
                                        style="width: 30px;height:30px"/>
                                </div>
                                <div class="justify-center flex items-baseline q-pl-sm" style="color:#abc4ed">
                                    <h6 class="q-my-none">
                                        ${{STARPrice}}
                                    </h6>
                                </div>
                            </div>
                            <p class="label q-mt-sm" style="padding-left:40px;">STAR Current Price</p>
                        </div>
                        <div class="q-pt-lg q-pb-md">
                            <div class="flex items-center">
                                <div>
                                    <img :src="require(`@/assets/images/lp.png`)" style="width: 30px;height:30px"/>
                                </div>
                                <div class="justify-center flex items-baseline q-pl-sm" style="color:#abc4ed">
                                    <h6 class="q-my-none">
                                        ${{STARBackingPrice}}
                                    </h6>
                                </div>
                            </div>
                            <p class="label q-mt-sm" style="padding-left:40px;">STAR Backing Price</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-md-8 col-lg-8 q-mt-md">
                <div class="justify-center items-center q-mb-md">
                    <h3 class="treTitle">
                        Get your STAR tokens at a discounted price with ASTR or STAR-ASTR LP tokens
                    </h3>
                </div>
                <div class="pools trePool">
                    <q-table
                        title=""
                        class="transparent custom-table"
                        :data="filteredTreasury"
                        :columns="columns"
                        row-key="id"
                    >
                        <template v-slot:body="props">
                            <q-tr :props="props" @click.native="isMetaMaskConnected ? props.expand = !props.expand : null" class="cursor-pointer">
                                <q-td>
                                    <div class="flex items-center no-wrap">
                                        <span v-if = "props.row.fromImage != ''" class="avatar">
                                            <img :src="props.row.fromImage">
                                        </span>
                                        <span v-if = "props.row.toImage != ''" class="avatar">
                                            <img :src="props.row.toImage">
                                        </span>
                                        <div class="q-ml-xs">
                                            <div class="q-mb-none q-mt-none">
                                                {{props.row.fund}}
                                            </div>
                                        </div>
                                    </div>
                                </q-td>
                                <q-td class="text-bold">
                                    <div>
                                        <span class="">{{STARbalance(props.row.id)}} </span>
                                        <span class="token">STAR</span>
                                    </div>
                                </q-td>
                                <q-td class="text-right text-bold">
                                    <div>
                                        <span class="">{{fundAmount(props.row.id)}} </span>
                                        <span class="token">{{props.row.fund}}</span>
                                    </div>
                                </q-td>
                            </q-tr>
                            <q-tr v-show="props.expand && isMetaMaskConnected" :props="props">
                                <q-td colspan="5" style="border-radius:20px" class="subPanel">
                                    <div class="row justify-center">
                                        <div class="col-12 justify-between items-center text-bold">
                                            <div class="row">
                                                <div class="col-sm-12 col-md-4 text-center q-mt-sm">
                                                    <span class = 'label'>Total</span><br>
                                                        {{purchasedSTAR(props.row.id)}} 
                                                        <span class="token">STAR</span>
                                                </div>
                                                <div class="col-sm-12 col-md-4 text-center q-mt-sm">
                                                    <span class = 'label'>Claimable</span><br>
                                                        {{pendingSTAR(props.row.id)}} 
                                                        <span class="token">STAR</span>
                                                </div>
                                                <div class="col-sm-12 col-md-4 text-center q-mt-sm">
                                                    <span class = 'label'>Earned</span><br>
                                                        {{earnedSTAR(props.row.id)}} 
                                                        <span class="token">STAR</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-11 q-mt-md">
                                            <q-btn rounded outline color="blue" size="sm" 
                                                @click="selection(props.row.id)">
                                                <span class="q-mr-sm">
                                                {{getActionName(props.row.id)}}
                                                </span><q-badge rounded>?</q-badge>
                                            </q-btn>
                                        </div>
                                        <div v-if="depositInfo(props.row.id)" class="depositInfo q-mt-md">
                                            <q-table
                                                id="farming"
                                                title=""
                                                class="transparent custom-table1 q-py-sm"
                                                :data="filteredLocked(props.row.id)"
                                                :columns="columns2"
                                                row-key="id"
                                                table-class="my-custom"
                                                v-if="!isMobile"
                                                :dense="$q.screen.lt.md"
                                            >
                                            </q-table>
                                            <q-table
                                                id="farming"
                                                title=""
                                                class="transparent custom-table1 q-py-sm"
                                                :data="filteredLocked(props.row.id)"
                                                :columns="columns2"
                                                row-key="id"
                                                table-class="my-custom"
                                                v-if="isMobile && props.row.id == 0"
                                                hide-header
                                            >
                                            <template v-slot:body="props">
                                                <q-tr v-show="!props.expand" :props="props" 
                                                    class="cursor-pointer">
                                                    <q-td class="text-bold" style="font-size:0.6rem;">
                                                        <span style="color: rgb(255, 255, 0, 0.8);margin-top:15px;">Locked Date</span><br>
                                                        {{getLockedDate(props.row.id, 0)}}
                                                    </q-td>
                                                    <q-td class="text-bold" style="font-size:0.6rem;">
                                                        <span style="color: rgb(255, 255, 0, 0.8);q-py-sm">Earned STAR</span><br>
                                                        {{getEarnedToken(props.row.id, 0)}} 
                                                        <span style="color:#abc4ff80">LP</span>
                                                        <div class="flex">
                                                            <div class="q-mr-md">
                                                            </div>
                                                            <div>
                                                                <span style="color: rgb(255, 255, 0, 0.8);">Tax</span><br>
                                                                <div style="margin-top:10px;">
                                                                    {{getTax(props.row.id, 0)}}%
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </q-td>
                                                </q-tr>
                                            </template>
                                            </q-table>
                                            <q-table
                                                id="farming"
                                                title=""
                                                class="transparent custom-table1 q-py-sm"
                                                :data="filteredLocked(props.row.id)"
                                                :columns="columns2"
                                                row-key="id"
                                                table-class="my-custom"
                                                v-if="isMobile && props.row.id == 1"
                                                hide-header
                                            >
                                            <template v-slot:body="props">
                                                <q-tr v-show="!props.expand" :props="props" 
                                                    class="cursor-pointer">
                                                    <q-td class="text-bold" style="font-size:0.6rem;">
                                                        <span style="color: rgb(255, 255, 0, 0.8);margin-top:15px;">Locked Date</span><br>
                                                        {{getLockedDate(props.row.id, 1)}}
                                                    </q-td>
                                                    <q-td class="text-bold" style="font-size:0.6rem;">
                                                        <span style="color: rgb(255, 255, 0, 0.8);q-py-sm">Earned STAR</span><br>
                                                        {{getEarnedToken(props.row.id, 1)}} 
                                                        <span style="color:#abc4ff80">LP</span>
                                                        <div class="flex">
                                                            <div class="q-mr-md">
                                                            </div>
                                                            <div>
                                                                <span style="color: rgb(255, 255, 0, 0.8);">Tax</span><br>
                                                                <div style="margin-top:10px;">
                                                                    {{getTax(props.row.id, 1)}}%
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </q-td>
                                                </q-tr>
                                            </template>
                                            </q-table>
                                        </div>
                                        <div class="text-h7 col-12 justify-center flex q-pt-md q-pb-md">
                                            <div class="btn q-px-md" @click="claim(props.row.id)">
                                                Claim STAR
                                            </div>
                                        </div>
                                        <div class="cols-12 col-sm-11">
                                            <div class="flex justify-between items-center q-mb-sm ">
                                                <div class="text-left flex">
                                                    <div class="box-outlined label" style="margin-top:12px">
                                                        Lock Days :
                                                    </div>
                                                    <div class="q-px-sm">
                                                        <q-select
                                                            outlined dense
                                                            v-model="lockday[props.row.id]" 
                                                            :options="lockdays" 
                                                            @input="changeLockday(props.row.id)"
                                                            class="count"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="box-outlined q-pt-sm " v-if="props.row.id == 0">
                                                    <span class="label">STAR : </span>{{STARAmount0}}
                                                </div>
                                                <div class="box-outlined q-pt-sm " v-if="props.row.id == 1">
                                                    <span class="label">STAR : </span>{{STARAmount1}}
                                                </div>
                                                <div class="box-outlined q-pt-sm text-right">
                                                    <span class="label">Discount : </span>{{lockday[props.row.id]}} 
                                                    <span class="token">%</span>
                                                </div>
                                            </div>
                                            <div class="sol treasury">
                                                <q-input
                                                    v-if="props.row.id == 0"
                                                    style="margin-left:0; margin-right:0;"
                                                    standout="bg-indigo-5" type="text" 
                                                    :key="'input'+rerender"
                                                    @input="inputToken1"
                                                    placeholder="0.00" 
                                                    v-model="amountFund[props.row.id]"
                                                    :error-message="errorMessage1()" 
                                                    :error="errorMessage1()!=null" 
                                                    :bottom-slots="false" 
                                                    :hide-bottom-space="true">
                                                    <template v-slot:prepend>
                                                        <div class="dropdown">
                                                            <button @click="showPercent1(props.row.id)" 
                                                                class="dropbtn mx-2 dropdown-toggle">
                                                                {{getCurrentPercent(props.row.id)}}%</button>
                                                            
                                                            <div :id="getPercentId(props.row.id)" class="percent dropdown-content" 
                                                                v-click-outside="onClickOutside1">
                                                                <ul>
                                                                    <li @click="setPercent1(0, props.row.id)">25%</li>
                                                                    <li @click="setPercent1(1, props.row.id)">50%</li>
                                                                    <li @click="setPercent1(2, props.row.id)">75%</li>
                                                                    <li @click="setPercent1(3, props.row.id)">100%</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </q-input>
                                                <q-input
                                                    v-if="props.row.id == 1"
                                                    style="margin-left:0; margin-right:0;"
                                                    standout="bg-indigo-5" type="text" 
                                                    :key="'input'+rerender"
                                                    @input="inputToken2"
                                                    placeholder="0.00" 
                                                    v-model="amountFund[props.row.id]"
                                                    :error-message="errorMessage1()" 
                                                    :error="errorMessage1()!=null" 
                                                    :bottom-slots="false" 
                                                    :hide-bottom-space="true">
                                                    <template v-slot:prepend>
                                                        <div class="dropdown">
                                                            <button @click="showPercent1(props.row.id)" 
                                                                class="dropbtn mx-2 dropdown-toggle">
                                                                {{getCurrentPercent(props.row.id)}}%</button>
                                                            
                                                            <div :id="getPercentId(props.row.id)" class="percent dropdown-content" 
                                                                v-click-outside="onClickOutside1">
                                                                <ul>
                                                                    <li @click="setPercent1(0, props.row.id)">25%</li>
                                                                    <li @click="setPercent1(1, props.row.id)">50%</li>
                                                                    <li @click="setPercent1(2, props.row.id)">75%</li>
                                                                    <li @click="setPercent1(3, props.row.id)">100%</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </q-input>
                                                <div v-if="!isApproved(props.row.id)" 
                                                        class="mintBtn q-mt-md" 
                                                        @click="approve(props.row.id)">
                                                        Approve
                                                </div>
                                                <div 
                                                        v-if="isApproved(props.row.id)" 
                                                        class="mintBtn q-mt-md" 
                                                        @click="buySTAR(props.row.id)">
                                                        Buy STAR
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </q-td>
                            </q-tr>
                        </template>
                    </q-table>
                </div>
                <div>
                    <div class="treTitle q-mt-lg q-mb-sm">
                        Treasury Price Guarantee
                    </div>
                    <div class="pools trePool q-pt-md" style="padding-top:20px !important;">
                        <q-table
                            title=""
                            class="transparent custom-table buyback"
                            :data="filteredTreasury1"
                            :columns="columns1"
                            row-key="id"
                            hide-header
                        >
                            <template v-slot:body="props">
                                <q-tr :props="props" @click.native="isMetaMaskConnected ? props.expand = !props.expand : null" class="cursor-pointer">
                                    <q-td>
                                        <div class="flex items-center no-wrap">
                                            <span v-if = "props.row.fromImage != ''" class="avatar">
                                                <img :src="props.row.fromImage">
                                            </span>
                                            <span v-if = "props.row.toImage != ''" class="avatar">
                                                <img :src="props.row.toImage">
                                            </span>
                                            <div class="q-ml-xs">
                                                <div class="q-mb-none q-mt-none text-bold">
                                                    {{props.row.fund}}
                                                </div>
                                            </div>
                                        </div>
                                    </q-td>
                                    <q-td>
                                    </q-td>
                                    <q-td>
                                    </q-td>
                                </q-tr>
                                <q-tr v-show="props.expand && isMetaMaskConnected" :props="props">
                                    <q-td colspan="5" style="border-radius:20px" class="subPanel">
                                        <p class="q-my-md q-mx-lg q-px-lg">Your STAR tokens come with a guaranteed backing from StarSwap treasury.
                                        </p>
                                        <div class="row justify-between items-center">
                                            <div class="col-sm-12 col-md-4 box-outlined q-pt-sm text-center ">
                                                <span class="label">Earned</span><br>
                                                {{earnedASTR}}
                                                <span class="token">ASTR</span>
                                            </div>
                                            <div class="col-sm-12 col-md-4 box-outlined q-pt-sm q-px-sm text-center">
                                                <span class="label">Buyable ASTR</span><br>
                                                {{ASTRAmount}}
                                                <span class="token">ASTR</span>
                                            </div>
                                            <div class="col-sm-12 col-md-4 box-outlined q-pt-sm q-px-sm text-center">
                                                <span class="label">Selled</span><br>
                                                {{selledSTAR}}
                                                <span class="token">STAR</span>
                                            </div>
                                        </div>
                                        <div class="row justify-center">
                                            <div class="cols-12 col-sm-11 q-mt-lg q-pa-sm">
                                                <div>
                                                    <div class="sol explorer q-mt-sm treasury">
                                                        <q-input
                                                            style="margin-left:0; margin-right:0;"
                                                            standout="bg-indigo-5" type="text" 
                                                            :key="'input'+rerender"
                                                            @input="inputToken3"
                                                            placeholder="0.00" v-model="amountSTAR"
                                                            :error-message="errorMessage2()" :error="errorMessage2()!=null" :bottom-slots="false" :hide-bottom-space="true">
                                                            <template v-slot:prepend>
                                                                <div class="dropdown">
                                                                    <button @click="showPercent()" 
                                                                        class="dropbtn mx-2 dropdown-toggle">
                                                                        {{currentPercent}}%</button>
                                                                    
                                                                    <div id="percent" class="percent dropdown-content" 
                                                                        v-click-outside="onClickOutside1">
                                                                        <ul>
                                                                            <li @click="setPercent(0)">25%</li>
                                                                            <li @click="setPercent(1)">50%</li>
                                                                            <li @click="setPercent(2)">75%</li>
                                                                            <li @click="setPercent(3)">100%</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </template>
                                                        </q-input>
                                                    </div>
                                                    <div v-if="!isApprovedSTAR" 
                                                            class="mintBtn q-mt-md" 
                                                            @click="approveSTAR()">
                                                        Approve
                                                    </div>
                                                    <div v-if="isApprovedSTAR" 
                                                            class="mintBtn q-mt-md" 
                                                            :disabled="!isBuyBack"
                                                            @click="sellSTAR()">
                                                        Sell STAR
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import BigNumber from 'bignumber.js';
    export default {
        name: "Farm",
        data() {
            return {
                rerender: 0,
                filter: 'all',
                banner: true,
                text: '',
                columns: [
                    {
                        name: 'pool',
                        field: 'pool',
                        label: 'Pool',
                        align: 'left',
                        sortable: true
                    },
                    {
                        name: 'STAR Balance',
                        field: 'STAR_balance',
                        label: 'STAR Balance',
                        align: 'left',
                        sortable: true
                    },
                    {
                        name: 'Fund',
                        field: 'fund',
                        label: 'Fund',
                        align: 'right',
                        sortable: true
                    },
                ],
                columns1: [
                    {
                        name: '',
                        field: '',
                        label: '',
                    },
                ],
                columns2: [
                    {
                        name: 'expiry',
                        field: 'expiry',
                        label: 'LOCKED DATE',
                        align: 'center',
                        sortable: true
                    },
                    {
                        name: 'lockedSTAR',
                        field: 'lockedSTAR',
                        label: 'EARNED STAR',
                        align: 'right',
                        sortable: true
                    },
                    {
                        name: 'discount',
                        field: 'discount',
                        label: 'DISCOUNT (%)',
                        align: 'center',
                        sortable: true
                    },
                ],
                rows2: [
                ],
                amountFund: [],
                amountSTAR: null,
                lockdays: [
                    '5', '6', '7', '8', '9', '10'
                ],
                lockday: [],
                buyableSTARAmount0: null,
                buyableSTARAmount1: null,
                buyableASTRAmount: null,
                pid: null,
                showModal: false,
                amountASTR: null,
                percents: [
                    '25', '50', '75', '100'
                ],
                currentPercent: 100,
                currentPercent1: [],
                percentIDs: [],
                showDepositInfo: [],
                currentPid:null,
            }
        },
        mounted() {
            this.lockday[0] = 5;
            this.lockday[1] = 5;
            this.percentIDs[0] = "percent_0";
            this.percentIDs[1] = "percent_1";

            this.$store.commit('read_treasury');

            this.showDepositInfo[0] = false;
            this.showDepositInfo[1] = false;
        },
        computed: {
            isMobile() {
                return this.$q.screen.width < 996;
            },
            isMetaMaskConnected() {
                return this.$store.state.account!=null;
            },
            filteredTreasury() {
                var rows = [];
                if (this.filter === 'all') {
                    rows = this.rows;
                } else {
                    rows = this.rows.filter(item => item.farm_type === this.filter);
                }
                return rows;
            },
            filteredTreasury1() {
                var rows = [];
                if (this.filter === 'all') {
                    rows = this.rows1;
                } else {
                    rows = this.rows1.filter(item => item.farm_type === this.filter);
                }
                return rows;
            },
            rows() {
                return [{
                    id: 0,
                    fund: 'ASTR',
                    fromImage: require('@/assets/images/tokens/astr.png'),
                    toImage: ''
                },
                {
                    id: 1,
                    fund: 'STAR-ASTR',
                    fromImage: require('@/assets/images/tokens/astr.png'),
                    toImage: require('@/assets/images/tokens/star.png'),
                }]
            },
            rows1() {
                return [
                {
                    id: 0,
                    fund: 'STAR Buy Back',
                    fromImage: require('@/assets/images/tokens/star.png'),
                    toImage: ''
                }]
            },
            STARAmount0() {
                return this.buyableSTARAmount0;
            },
            STARAmount1() {
                return this.buyableSTARAmount1;
            },
            treasuryBalance() {
                if(this.$store.state.treasury.treasuryBalance > 0) {
                    let value = BigNumber(this.$store.state.treasury.treasuryBalance)
                    .shiftedBy(-18);

                    return this.getDecimalData(BigNumber(value).times(BigNumber(this.$store.state.price.ASTR)));
                }

                return '0.00';
            },
            STARPrice() {
                if(this.$store.state.treasury.priceSTAR > 0) {
                    let value = BigNumber(this.$store.state.treasury.priceSTAR)
                    .shiftedBy(-18);

                    return this.getDecimalData(BigNumber(value).times(BigNumber(this.$store.state.price.ASTR)));
                }
                return '0.00';
            },
            STARBackingPrice() {
                if(this.$store.state.treasury.bakingPriceSTAR > 0) {
                    let value = BigNumber(this.$store.state.treasury.bakingPriceSTAR)
                    .shiftedBy(-18);

                    return this.getDecimalData(BigNumber(value).times(BigNumber(this.$store.state.price.ASTR)));
                }

                return '0.00';
            },
            poolInfo(index) {
                return this.$store.state.farming.poolInfo(index);
            },
            isBuyBack() {
                return this.$store.state.treasury.isEnableBuyBack;
            },
            earnedASTR() {
                if(BigNumber(this.$store.state.treasury.totalEarnedASTR).isNaN())
                    return '0.00';
                return this.getDecimalData(BigNumber(this.$store.state.treasury.totalEarnedASTR)
                        .shiftedBy(-18));
            },
            buyableASTR() {
                if(BigNumber(this.$store.state.treasury.totalSelledSTAR).isNaN())
                    return '0.00';
                return this.getDecimalData(BigNumber(this.$store.state.treasury.totalSelledSTAR)
                        .shiftedBy(-18));
            },
            selledSTAR() {
                if(BigNumber(this.$store.state.treasury.totalSelledSTAR).isNaN())
                    return '0.00';
                return this.getDecimalData(BigNumber(this.$store.state.treasury.totalSelledSTAR)
                        .shiftedBy(-18));
            },
            ASTRAmount() {
                if (this.buyableASTRAmount == null)
                    return '0.00';
                return this.buyableASTRAmount;
            },
            isApprovedSTAR() {
                return this.$store.state.approvedSTARTreasury;
            },
        },
        methods: {
            filteredLocked(pid) {
                var rows = [];
                if (pid == 0) {
                    let length = this.$store.state.treasury.purchasedInfoByASTR.length;

                    for (let i = 0; i < length; i++) {
                        rows[i] = {
                            id : i,
                            expiry: this.getDate(this.$store.state.treasury.purchasedInfoByASTR[i][3]),
                            lockedSTAR: this.getDecimalData(BigNumber(this.$store.state.treasury.purchasedInfoByASTR[i][2])
                                        .shiftedBy(-18)),
                            discount: this.$store.state.treasury.purchasedInfoByASTR[i][4] / 100,
                        }
                    }
                }
                else {
                    let length = this.$store.state.treasury.purchasedInfoByLP.length;
                    for (let i = 0; i < length; i++) {
                        rows[i] = {
                            id : i,
                            expiry: this.getDate(this.$store.state.treasury.purchasedInfoByLP[i][3]),
                            lockedSTAR: this.getDecimalData(BigNumber(this.$store.state.treasury.purchasedInfoByLP[i][2])
                                        .shiftedBy(-18)),
                            discount: this.$store.state.treasury.purchasedInfoByLP[i][4] / 100,
                        }
                    }
                }
                return rows
            },
            getLockedDate(index, pid) {
                if (pid == 0) {
                    return this.getDate(this.$store.state.treasury.purchasedInfoByASTR[index][3]);
                }
                else {
                    return this.getDate(this.$store.state.treasury.purchasedInfoByLP[index][3]);
                }
            },
            getEarnedToken(index, pid) {
                if (pid == 0) {
                    return this.getDecimalData(BigNumber(this.$store.state.treasury.purchasedInfoByASTR[index][2])
                        .shiftedBy(-18));
                }
                else {
                    return this.getDecimalData(BigNumber(this.$store.state.treasury.purchasedInfoByLP[index][2])
                        .shiftedBy(-18));
                }
            },
            getTax(index, pid) {
                if (pid == 0) {
                    return this.$store.state.treasury.purchasedInfoByASTR[index][4] / 100;
                }
                else {
                    return this.$store.state.treasury.purchasedInfoByLP[index][4] / 100;
                }
            },
            forceUpdate() {
                this.rerender++;
            },
            errorMessage1(index) {
                if(BigNumber(this.amountFund[index]).isGreaterThan(this.balanceFund(index))) {
                    return "INSUFFICIENT ACCOUNT BALANCE";
                }

                return null;
            },
            errorMessage2() {
                if(BigNumber(this.amountSTAR).isGreaterThan(this.balanceSTAR())) {
                    return "INSUFFICIENT ACCOUNT BALANCE";
                }
                return null;
            },
            isApproved(index) {
                if (index == 0)
                    return true;
                else
                    return this.$store.state.approvedPairTreasury;
            },
            approve(index) {
                if (index == 1)
                    this.$store.dispatch('approvePairTreasury');
            },
            approveSTAR() {
                this.$store.dispatch('approveSTARTreasury');
            },
            changeLockday(pid) {
                if (pid == 0) {
                    this.inputToken1(this.amountFund[pid]);
                }
                else {
                    this.inputToken2(this.amountFund[pid]);
                }
            },
            inputToken1(value) {
                if(parseFloat(value)>0) {
                    let params = {
                        pid: 0,
                        amount: BigNumber(this.amountFund[0]).shiftedBy(18).integerValue().toString(),
                        lockday: this.lockday[0]
                    };
                    this.$store.dispatch('getAmountSTARInTreasury',params).then((ret)=>{
                        this.buyableSTARAmount0 = this.getDecimalData(BigNumber(ret).shiftedBy(-18))
                    });
                } else 
                    this.buyableSTARAmount0 = "0.00";
            },
            inputToken2(value) {
                if(parseFloat(value)>0) {
                    let params = {
                        pid: 1,
                        amount: BigNumber(this.amountFund[1]).shiftedBy(18).integerValue().toString(),
                        lockday: this.lockday[1]
                    };
                    this.$store.dispatch('getAmountSTARInTreasury',params).then((ret)=>{
                        this.buyableSTARAmount1 = this.getDecimalData(BigNumber(ret).shiftedBy(-18))
                    });
                } else 
                    this.buyableSTARAmount1 = "0.00";
            },
            getMaxFund(index) {
                if(this.$store.state.account!=null) {
                    if (index == 0)
                        this.amountFund[index] = BigNumber(this.$store.state.balance.amountASTR)
                        .shiftedBy(-18);

                    if (index == 1)
                        this.amountFund[index] = BigNumber(this.$store.state.treasury.lpBalance)
                        .shiftedBy(-18);

                    this.forceUpdate();
                }
            },
            balanceFund(index) {
                if (index == 0) {
                    if(this.$store.state.account && this.$store.state.balance.amountASTR) {
                        return BigNumber(this.$store.state.balance.amountASTR)
                            .shiftedBy(-18);
                    }                   
                }
                else if (index == 1) {
                    if(this.$store.state.account && this.$store.state.treasury.lpBalance) {
                        return BigNumber(this.$store.state.treasury.lpBalance)
                            .shiftedBy(-18);
                    }          
                }
            },
            getMaxSTAR() {
                if(this.$store.state.account!=null) {
                    this.amountSTAR = BigNumber(this.$store.state.balance.amountSTAR)
                     .shiftedBy(-18);
                }
                this.forceUpdate();
            },
            balanceSTAR() {
                if(this.$store.state.account && this.$store.state.balance.amountSTAR) {
                    return BigNumber(this.$store.state.balance.amountSTAR)
                        .shiftedBy(-18);
                }                   
                return 0;
            }, 
            buySTAR(index) {
                this.$store.dispatch('buySTAR',{
                    index:index,
                    amount:this.amountFund[index],
                    lockday: this.lockday[index]
                });
            },
            inputToken3(value) {
                if(parseFloat(value)>0) {
                    let params = {
                        amount: BigNumber(this.amountSTAR).shiftedBy(18).integerValue().toString()
                    };
                    this.$store.dispatch('getAmountASTRInTreasury',params).then((ret)=>{
                        this.buyableASTRAmount = this.getDecimalData(BigNumber(ret).shiftedBy(-18))
                    });
                } else 
                    this.buyableASTRAmount = "0.00";
            },
            sellSTAR() {
                this.$store.dispatch('sellSTAR',{
                    amount:this.amountSTAR
                });
            },
            claim(index) {
                this.$store.dispatch('claimSTARinTreasury',{
                    index:index
                });
            },
            STARbalance(index) {
                if (index == 0) {
                    if(BigNumber(this.$store.state.treasury.remainedSTAR0).isGreaterThan(0))
                        return this.getDecimalData(BigNumber(this.$store.state.treasury.remainedSTAR0)
                        .shiftedBy(-18));
                    return '0.00';
                }
                else if (index == 1) {
                    if(BigNumber(this.$store.state.treasury.remainedSTAR1).isGreaterThan(0))
                        return this.getDecimalData(BigNumber(this.$store.state.treasury.remainedSTAR1)
                        .shiftedBy(-18));
                    return '0.00';
                }         
            },
            fundAmount(index) {
                if (index == 0) {
                    if(this.$store.state.treasury.totalFund0 > 0)
                        return this.getDecimalData(BigNumber(this.$store.state.treasury.totalFund0)
                        .shiftedBy(-18));
                    return '0.00';
                }
                else if (index == 1) {
                    if(this.$store.state.treasury.totalFund1 > 0)
                        return this.getDecimalData(BigNumber(this.$store.state.treasury.totalFund1)
                        .shiftedBy(-18));
                    return '0.00';             
                }         
            },
            purchasedSTAR(index) {
                if(BigNumber(this.$store.state.treasury.totalPhurchasedSTAR[index]).isNaN())
                    return "0.00";
                return this.getDecimalData(BigNumber(this.$store.state.treasury.totalPhurchasedSTAR[index])
                .shiftedBy(-18));
            },
            pendingSTAR(index) {
                if(BigNumber(this.$store.state.treasury.pendingSTAR[index]).isNaN())
                    return "0.00";
                return this.getDecimalData(BigNumber(this.$store.state.treasury.pendingSTAR[index])
                .shiftedBy(-18));
            },
            earnedSTAR(index) {
                if(BigNumber(this.$store.state.treasury.totalEarnedSTAR[index]).isNaN())
                    return "0.00";
                return this.getDecimalData(BigNumber(this.$store.state.treasury.totalEarnedSTAR[index])
                .shiftedBy(-18));
            },
            purchasedInfo(index) {
                if (index == 0)
                    return this.$store.state.treasury.purchasedInfoByASTR;
                else
                    return this.$store.state.treasury.purchasedInfoByLP;
            },
            getDate(amount) {
                let date = amount * 1000
                const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }
                const dtf = new Intl.DateTimeFormat('en-US', options);
                return dtf.format(date);
            },
            getDiscountRate(amount) {
                return BigNumber(amount)
                    .shiftedBy(-2);
            },
            selection(index) {
                this.currentPid = index;
                this.showDepositInfo[index] = !this.showDepositInfo[index];

                this.forceUpdate();
            },
            showPercent() {
                document.getElementById("percent").classList.toggle("show");
            },
            setPercent(value) {
                this.currentPercent = this.percents[value];

                if (value == 0)
                    this.amountSTAR = BigNumber(this.$store.state.balance.amountSTAR)
                     .shiftedBy(-18).div(4);
                else if (value == 1)
                    this.amountSTAR = BigNumber(this.$store.state.balance.amountSTAR)
                     .shiftedBy(-18).div(2);
                else if (value == 2)
                    this.amountSTAR = BigNumber(this.$store.state.balance.amountSTAR)
                     .shiftedBy(-18).times(3).div(4);
                else
                    this.amountSTAR = BigNumber(this.$store.state.balance.amountSTAR)
                     .shiftedBy(-18);

                if (this.amountSTAR == '0')
                    this.amountSTAR = '0.00';

                 document.getElementById("percent").classList.toggle("show");

            },
            onClickOutside1 (event) {
                if (!event.target.matches('.dropbtn')) {
                    let dropdowns = document.getElementsByClassName("dropdown-content");
                    let i;
                    for (i = 0; i < dropdowns.length; i++) {
                        let openDropdown = dropdowns[i];
                        if (openDropdown.classList.contains('show')) {
                            openDropdown.classList.remove('show');
                        }
                    }
                }
            },
            getCurrentPercent(index) {
                if (this.currentPercent1[index] == null)
                    return 100;
                return this.currentPercent1[index];
            },
            setPercent1(value, id) {
                this.currentPercent1[id] = this.percents[value];
                let balance;

                if (id == 0)
                    balance = BigNumber(this.$store.state.balance.amountASTR)
                    .shiftedBy(-18);

                if (id == 1)
                    balance = BigNumber(this.$store.state.treasury.lpBalance)
                    .shiftedBy(-18);

                if (value == 0)
                    this.amountFund[id] = this.getDecimalData(balance.div(4));
                else if (value == 1)
                    this.amountFund[id] = this.getDecimalData(balance.div(2));
                else if (value == 2)
                    this.amountFund[id] = this.getDecimalData(balance.times(3).div(4));
                else
                    this.amountFund[id] = this.getDecimalData(balance);

                if (this.amountFund[id] == '0')
                    this.amountFund[id] = '0.00';

                if (id == 0) {
                    this.inputToken1(this.amountFund[id]);
                }
                else {
                    this.inputToken2(this.amountFund[id]);
                }

                this.forceUpdate();

                document.getElementById("percent").classList.toggle("show");

            },
            getPercentId(index) {
                return this.percentIDs[index];
            },
            showPercent1(id) {
                let percentId;
                percentId = "percent_" + id;

                document.getElementById(percentId).classList.toggle("show");
            },
            getActionName(index) {
                if (this.showDepositInfo[index])
                    return "Hide Info"
                else
                    return "More Info"
            },
            depositInfo(index) {
                return this.showDepositInfo[index];
            },
            getDecimalData(value) {
                let limit_xxx = BigNumber(1000);
                let limit_xx = BigNumber(1);
                let limit_x = BigNumber(1).shiftedBy(-3);
                let limit_sm = BigNumber(1).shiftedBy(-6);
                let limit_md = BigNumber(1).shiftedBy(-9);
                let limit_lg = BigNumber(1).shiftedBy(-12);

                if (limit_lg.isGreaterThan(value)) {
                    return value.toString();
                }
                else if (limit_md.isGreaterThan(value)) {
                    return value.decimalPlaces(12, 1).toString();
                }
                else if (limit_sm.isGreaterThan(value)) {
                    return value.decimalPlaces(9, 1).toString();
                }
                else if (limit_x.isGreaterThan(value)) {
                    return value.decimalPlaces(6, 1).toString();
                }
                else if (limit_xx.isGreaterThan(value)) {
                    return value.decimalPlaces(4, 1).toString();
                }
                else if (limit_xxx.isGreaterThan(value)) {
                    return value.decimalPlaces(2, 1).toString();
                }
                else {
                    return value.decimalPlaces(2, 1).toString();
                }
            }
        }
    }
</script>

<style scoped>
    .filterSection button {
        background: black;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 20px 10px;
        border-radius: 7px;
        margin: 10px;
        width: 90%;
        color: white;
        border: none;
        cursor: pointer;
    }

    .filterSection button.active {
        background-image: linear-gradient(to right, var(--q-color-primary), var(--q-color-info));
        color: #fff;
        font-weight: 600;
    }
/* 
    .custom-table tbody td {
        padding: 20px 10px;
    }

    .buyback >>> .q-table thead {
        display: none !important;
    }

    .custom-table >>> .q-table__bottom {
        display: none !important;
    }

    .custom-table >>> .q-table__title {
        font-size: 20px !important;
        color:rgb(255, 255, 0, 0.8)
    } */

    .link{
        color: var(--q-color-info);
        margin: 5px 0;
        text-decoration: none;
    }

    .link:hover{
        color: rgb(255, 255, 255);
    }

    .treasury__status {
        background-color: #42a1e821; 
        border-radius: 20px; 
        color: #fff;
    }

    .title {
        width: 100%;
        border-bottom: 1px solid #0993ec60;
        padding: 5px;
    }
    .lists {
        overflow-y: auto;
        max-height: 300px;
    }

    /* .q-item {
        min-height: 20px !important;
    } */

    /* .modal {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #0000006c;
        backdrop-filter: blur(10px);
        z-index: 2000;
        display: flex;
        justify-content: center;
        align-items: center;
    } */

    /* .modal__content {
        width: 500px;
        padding: 10px 20px;
        border: 1px double transparent;
        border-radius: 10px;
        background-image: linear-gradient(224deg, var(--q-color-secondary), var(--q-color-dark)),
        linear-gradient(224deg, var(--q-color-info), var(--q-color-primary));
        color: #fff;
        background-origin: border-box;
        background-clip: padding-box, border-box;
    }
    .model__content__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0;
    } */

    .buyback >>> .q-table--no-wrap th, .q-table--no-wrap td {
        white-space:pre-wrap;
    }

    .custom-table1 >>> thead tr:first-child th {
        color: rgb(255, 255, 0, 0.8);
        font-size: 12px;
        font-weight: 500;
    }

    .subTitle {
        font-size: 1rem;
        color:rgba(255, 255, 255, 0.5) !important;
        font-weight: 500;
    }

    .treTitle {
        font-size: 1.1rem !important;
        color: #ace3e5 !important;
        font-weight: 500;
    }

    .trePool {
        padding-top:0;
        padding-bottom:10px;
        background: #42a1e821;
        background: -moz-linear-gradient(left,  rgba(22,31,70,1) 0%, rgba(25,19,79,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  rgba(22,31,70,1) 0%,rgba(25,19,79,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right,  rgba(22,31,70,1) 0%,rgba(25,19,79,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }

    .count .row {
        padding-left: 10px !important;
        padding-right:2px;
        margin:0 !important;
        color:#39d0d8;
    }
    .count >>> .q-field__control {
        border: 1px solid #39d0d84f !important;
        font-size: 14px;
    }
    .q-field {
        font-size: 14px;
    }

    .count .col {
        padding:0 !important;
        margin:0 !important;
        font-size: 14px !important;
    }

    .count >>> .q-field__marginal {
        height: 42px !important;
        padding:0 !important
    }

    .depositInfo {
        border:1px solid grey; 
        background: linear-gradient(135deg, #1b3f55 0%,#15223d 100%);
        font-size:10px !important;
        border-radius: 5px;
        width:95%;

        /* border: thin solid transparent;
        border-radius: 5px;
        background-image: linear-gradient(224deg, var(--q-color-secondary), var(--q-color-dark)),
        linear-gradient(224deg, var(--q-color-info), var(--q-color-primary));
        color: #fff;
        background-origin: border-box;
        background-clip: padding-box, border-box; */
    }

    .depositInfo >>> table {
        border-collapse: separate;
        border-spacing: 0 0.4rem !important;
    }

    .depositInfo >>> .q-table thead tr, 
    .depositInfo >>> .q-table tbody td {
        height: 35px !important;
        font-size:14px !important;
    }

    .depositInfo >>> table thead th {
        padding: 10px !important;
    }

    .depositInfo >>> table thead  tr th:last-child {
        padding-right:0px !important;
    }

    .treasury >>> .q-field .row {
        padding: 0 !important;
        margin: 0 !important;
    }
</style>
